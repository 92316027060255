/* Ad playing */
.vjs-ad-playing {
  .vjs-progress-control {
    pointer-events: none;
  }
  cursor: pointer;
  &.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1;
  }

  // Hide buttons
  .vjs-control-bar {
    cursor: default;
    .vjs-seek-button,
    .vjs-current-time,
    .vjs-chromecast-button,
    .vjs-playback-rate,
    .vjs-chapters-button,
    .vjs-descriptions-button,
    .vjs-subs-caps-button,
    .vjs-audio-button,
    .vjs-quality-selector,
    .vjs-picture-in-picture-control,
    .vjs-fullscreen-control {
      display: none !important; // Sorry for this...
    }
    .vjs-progress-control {
      .vjs-progress-holder,
      .vjs-play-progress,
      .vjs-slider-bar,
      .vjs-load-progress,
      .vjs-load-progress div {
        background: #ffe400;
      }
    }
  }

  .vjs-big-play-button {
    display: none;
  }
}
