html {
  overflow: hidden;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  font-family: sans-serif;
  background-color: transparent;
}

#root {
  height: 100%;
  min-height: 100%;
}
