#root #vjs_video_3,
#root video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.title_overlay {
  position: absolute;
  z-index: 100;
  opacity: 1;
  visibility: visible;
  transition:
    visibility 0s 0.5s,
    opacity 0.5s linear;
  color: #f49500;
}

.title_overlay h1 {
  font-size: 1.5rem;
}

.endcap_overlay {
  position: absolute;
  z-index: 100;

  visibility: visible;
  transition:
    visibility 0s 0.5s,
    opacity 0.5s linear;
  color: #f49500;
}

.endcap_overlay .endcap_title h1 {
  position: relative;
  font-size: 1.5rem;
}

.endcap_overlay .player_suggestions {
  display: inline-block;
  position: relative;
  cursor: pointer;
  max-width: 30vw;
}

.endcap_overlay .player_suggestions h1 {
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  font-size: 1.2rem;
}

.endcap_overlay .player_suggestions img {
  margin: auto;
  display: block;
  width: 100%;
}

.endcap_overlay .player_suggestions_overlay {
  position: absolute;
  bottom: 0;
  right: 1.5rem;
}

.endcap_overlay .player_suggestions_overlay h2 {
  font-size: 1rem;
  font-weight: normal;
}

.endcap_overlay.ad_endcap_overlay {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  text-align: center;
}

.endcap_overlay.ad_endcap_overlay .btn-link {
  color: #f8f9fa;
}

.video-js {
  /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
  font-size: 10px;
  /* The main font color changes the ICON COLORS as well as the text */
  color: #fff;
  background-color: transparent !important;
}

/* Show seek buttons on bigger screens only */
button.vjs-seek-button.skip-back.skip-10.vjs-control.vjs-button,
button.vjs-seek-button.skip-forward.skip-10.vjs-control.vjs-button {
  display: none;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: unset;
  margin-left: unset;
}

@media only screen and (min-width: 500px) {
  .video-js {
    font-size: 12px;
  }

  button.vjs-seek-button.skip-back.skip-10.vjs-control.vjs-button,
  button.vjs-seek-button.skip-forward.skip-10.vjs-control.vjs-button {
    display: block;
  }
}

/* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background-color: #4b555c;
  /* Opacity: 1.0 = 100%, 0.0 = 0% */
  background-color: rgba(75, 85, 92, 0.7);
}

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
  background-color: #f49500;
  background-color: rgba(244, 149, 0, 0.5);
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
  background: #f49500;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress {
  /* For IE8 we'll lighten the color */
  background: #ffc975;
  /* Otherwise we'll rely on stacked opacities */
  background: rgba(244, 149, 0, 0.5);
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
  /* For IE8 we'll lighten the color */
  background: #fffbf4;
  /* Otherwise we'll rely on stacked opacities */
  background: rgba(244, 149, 0, 0.75);
}

.video-js .vjs-control {
  width: 3.3em;
}

.video-js .vjs-time-control {
  width: 4em;
  padding: 0px;
}

.vjs-chromecast-button .vjs-icon-placeholder,
.vjs-airplay-button .vjs-icon-placeholder {
  height: 100% !important;
  width: 1.5em !important;
}

/* Thumb display mod */
.vjs-vtt-thumbnail-display {
  /**
  This shows a small orange dot when not loaded yet
   */
  /*outline: solid 1px rgba(244, 149, 0, 0.5);*/
}

.video-js .vjs-big-play-button,
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  min-height: 100px !important;
  width: 14%;
  height: 26.6%;
  background-color: rgba(0, 0, 0, 0);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  overflow: visible;
  background-image: url("./assets/images/play_button.png");
}

/* Hide play button initially */
.video-js .vjs-big-play-button {
  opacity: 0;
}

@media (max-width: 640px) {
  .video-js .vjs-big-play-button {
    min-height: 50px !important;
  }
}

.video-js .vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}

/* Safari fix */
.video-js .vjs-seek-button.skip-forward::before,
.video-js.vjs-v6 .vjs-seek-button.skip-forward .vjs-icon-placeholder::before,
.video-js.vjs-v7 .vjs-seek-button.skip-forward .vjs-icon-placeholder::before {
  -webkit-transform: rotate(45deg) scaleX(-1);
}

/* Video.JS dialogs always on top */
.vjs-modal-dialog {
  z-index: 1000;
}
