.App {
  width: 100%;
  height: 100%;
}

/* Disables the blue highlight on mobile browsers */
.vjs-play-control:focus,
.vjs-control:focus,
.vjs-progress-holder:focus,
.vjs-button:focus {
  outline: none;
}
/* Hides play button of native safari */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

@import "jsAds";
